import {Button, Col, Divider, InputRef, notification, Row, Space, Statistic, Switch} from "antd";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {APInvoiceTrackerData, APInvoiceTrackerDataRenderType, LedgerData} from "../../../types/OmegaTypes";
import {makeAPTrackerColumns, makeColumnsEditable} from "../../utilities/OMSColumns";
import {EditableColumnType} from "../table/EditableCell";
import * as _ from "lodash";
import {UnsavedChangesHandler} from "../../utilities/UnsavedChangesHandler";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import {deleteAPTrackerData, generateTrackerData, getAPTrackerData, updateAPTrackerData} from "../../../services/WholesaleService";
import {CopyOutlined, DeleteFilled, DollarOutlined, PlusOutlined} from "@ant-design/icons";
import {validateAPTracker} from "../../utilities/TableDataValidation";
import EditableTable, {EditableTableHandle} from "../table/EditableTable";
import {CSVDownload} from "../../utilities/CSVDownload";
import dayjs from "dayjs";
import * as dataForge from "data-forge";
import {OMSDateStatsSpace} from "../../utilities/OMSDateStatsSpace";
import {BulkActionsButton, BulkActionType, useBoolColumnModal} from "../table/BulkActions";
import {Collapse} from "antd";
import AddLedgerModal from "./AddLedgerModal";
import {useSuppliers} from "./dataHandlers";

const {Panel} = Collapse;

const defaultItem: APInvoiceTrackerData = {
    IssueDate: new Date(),
    DueDate: new Date(),
    Brand: "",
    Distributor: "",
    Supplier_PO: "",
    Supplier_SO: undefined,
    InvoiceId: undefined,
    InvoiceNotes: undefined,
    OrderAmount: 0,
    PaymentNotes: undefined,
    PaidAmount: undefined,
    PaidDate: undefined,
    IsPaid: undefined,
    IsCredit: undefined,
    OtherNotes: undefined,
    InvoiceFileURL: undefined,
};

const getCurrentPeriod = (data: APInvoiceTrackerData[]) => {
    const startOfPeriod = dayjs("2023-12-11");
    const today = dayjs();

    // periods are 2 weeks long, find out the current period
    const periodLength = 14;
    const diff = today.diff(startOfPeriod, "day");
    const period = Math.floor(diff / periodLength);

    // find the start and end date of current period
    const startDate = startOfPeriod.add(period * periodLength, "day").startOf("day");

    // find the start and end dates for the next three periods
    const nextPeriods = [];
    for (let i = 0; i < 4; i++) {
        const nextStartDate = startDate.add(i * periodLength, "day").startOf("day");
        const nextEndDate = nextStartDate.add(periodLength - 1, "day").endOf("day");
        nextPeriods.push({
            startDate: nextStartDate,
            endDate: nextEndDate,
            currentPeriodData: data.filter((d) => {
                const date = dayjs(d["DueDate"]);
                return date.isAfter(nextStartDate) && date.isBefore(nextEndDate);
            }),
        });
    }

    return nextPeriods;
};

const appendPeriodData = (periodData: any, data: APInvoiceTrackerDataRenderType[]) => {
    const periodTableData = data.map((item, idx) => ({
        ...item,
        periodIndex:
            periodData.findIndex((period: any) => {
                const date = dayjs(item["DueDate"]);
                return date.isAfter(period.startDate) && date.isBefore(period.endDate);
            }) + 1,
    }));
    return periodTableData;
};

export const APInvoiceTracker: React.FC = () => {
    const {currentUser} = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<APInvoiceTrackerDataRenderType[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date>(fetchTimestamp);
    const [editable, setEditable] = useState(false);
    const [showUnpaid, setShowUnpaid] = useState(false);
    const queryClient = useQueryClient();
    const tableRef = useRef<EditableTableHandle<APInvoiceTrackerDataRenderType>>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [boolModal, boolModalContext] = useBoolColumnModal<APInvoiceTrackerDataRenderType>(tableData, selectedRowKeys);
    const [ledgerModalOpen, setLedgerModalOpen] = useState(false);
    const [ledgerModalData, setLedgerModalData] = useState<Partial<LedgerData>>({});
    const [clickedItem, setClickedItem] = useState<APInvoiceTrackerDataRenderType | undefined>(undefined);

    const [periodData, setPeriodData] = useState<any[]>([]);

    const {
        data: apTrackerData,
        isLoading: apTrackerDataLoading,
        isRefetching,
        error,
    } = useQuery({
        queryKey: ["ap_tracker"],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            const names = (await getAPTrackerData(token)) as APInvoiceTrackerData[];
            return names;
        },
    });

    useEffect(() => {
        if (error) {
            notification.error({
                message: "Error",
                // @ts-ignore
                description: error.message,
            });
        }
    }, [error]);

    const {data: suppliers} = useSuppliers();

    const syncTableData = useCallback(() => {
        if (apTrackerData && suppliers) {
            const newTableData = apTrackerData.map((item, idx) => ({
                ...item,
                key: idx,
                isNew: false,
                ccAcceptance: suppliers.find((supplier) => supplier.name === item.Brand)?.ccAcceptance || "",
                paymentProcess: suppliers.find((supplier) => supplier.name === item.Brand)?.paymentProcess || "",
                VendorType: suppliers.find((supplier) => supplier.name === item.Brand)?.VendorType || "",
            }));
            const newPeriodData = getCurrentPeriod(newTableData);
            const periodTableData = appendPeriodData(newPeriodData, newTableData);
            setPeriodData(newPeriodData);
            setTableData(periodTableData);
            setLastUpdateTimestamp(fetchTimestamp);
        }
    }, [apTrackerData, fetchTimestamp, suppliers]);

    const omsMutation = useMutation({
        mutationFn: async (data: APInvoiceTrackerData[]) => {
            const token = await currentUser!.getIdToken();

            if (!token) {
                throw new Error("Auth token not found");
            }

            if (!apTrackerData) {
                throw new Error("AP Tracker data was not fetched correctly");
            }

            const originalIds = apTrackerData.map((item) => item._id);
            const localIds = data.map((item) => item._id);
            const idsToDelete = _.difference(originalIds, localIds);
            const returnedInvoices = await updateAPTrackerData(token, data);
            await deleteAPTrackerData(token, idsToDelete.filter((id) => id !== undefined) as string[]);
            generateTrackerData(token, false);

            return returnedInvoices;
        },
        onSuccess: (data) => {
            queryClient.setQueryData(["ap_tracker"], data);
            const tableData = data.map((item, idx) => ({
                key: idx,
                isNew: false,
                ...item,
            }));

            const newPeriodData = getCurrentPeriod(tableData);
            setPeriodData(newPeriodData);
            const periodTableData: APInvoiceTrackerDataRenderType[] = appendPeriodData(newPeriodData, tableData);
            setTableData(periodTableData);
            setFetchTimestamp(new Date());
            notification.success({
                message: "Push successful!",
            });
        },
        onError: (error) => {
            notification.error({
                message: "Upload error",
                // @ts-ignore
                description: error.message,
            });
        },
    });

    const updateData = (newData: APInvoiceTrackerDataRenderType[]) => {
        const newPeriodData = getCurrentPeriod(newData);
        const periodTableData: APInvoiceTrackerDataRenderType[] = appendPeriodData(newPeriodData, newData);
        setPeriodData(newPeriodData);
        setTableData(periodTableData);
        setLastUpdateTimestamp(new Date());
    };

    const handleSave = useCallback(
        (row: APInvoiceTrackerDataRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            updateData(newData);
        },
        [tableData]
    );

    useEffect(() => {
        syncTableData();
    }, [apTrackerData, syncTableData]);

    const addNewRow = useCallback(
        (newRowProps: any = {}) => {
            const newItem: APInvoiceTrackerDataRenderType = {
                ...defaultItem,
                key: 0,
                IssueDate: new Date(),
                DueDate: new Date(),
                IsCredit: false,
                isNew: true,
                ...newRowProps,
            };
            const newData = [newItem, ...tableData];
            setTableData(newData.map((item, idx) => ({...item, key: idx})));
        },
        [tableData]
    );

    const columns = useMemo<EditableColumnType<APInvoiceTrackerDataRenderType>[]>(() => {
        const apTrackerColumns = makeAPTrackerColumns(searchInputRef);
        if (editable) {
            apTrackerColumns.push({
                title: "Action",
                dataIndex: "",
                key: "x",
                render: (_, record) => (
                    <Space direction="horizontal">
                        <Button
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setLedgerModalOpen(true);
                                setLedgerModalData({
                                    Supplier_Name: record.Brand,
                                    InvoiceId: record.InvoiceId,
                                    Supplier_PO: record.Supplier_PO,
                                    Supplier_SO: record.Supplier_SO,
                                    PaidAmount: record.OrderAmount,
                                });
                                setClickedItem(record);
                            }}
                        />
                        <Button
                            icon={<CopyOutlined />}
                            onClick={() => {
                                addNewRow({
                                    IssueDate: record.IssueDate,
                                    DueDate: record.DueDate,
                                    Brand: record.Brand,
                                    Distributor: record.Distributor,
                                });
                            }}
                        />
                        <Button
                            danger
                            icon={<DeleteFilled />}
                            onClick={() => {
                                const dataCopy = [...tableData];
                                const newData = dataCopy.filter((item) => (record._id ? item._id !== record._id : item.key !== record.key));

                                setLastUpdateTimestamp(new Date());
                                setTableData(
                                    newData.map((item, idx) => ({
                                        ...item,
                                        key: idx,
                                    }))
                                );
                            }}
                        />
                    </Space>
                ),
                fixed: "right",
                width: "60px",
            });
            return makeColumnsEditable(apTrackerColumns, handleSave);
        } else {
            return apTrackerColumns;
        }
    }, [handleSave, editable, tableData, addNewRow]);

    const onDiscardChanges = () => {
        if (apTrackerData) {
            setTableData(
                apTrackerData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
        }
        setLastUpdateTimestamp(fetchTimestamp);
    };

    const onSaveChanges = () => {
        const errors = validateAPTracker(tableData);

        if (tableData.filter((row) => row.isNew).length > 0) {
            // if the new items have DueDate shorter than 20 days from IssueDate, show warning
            const newItems = tableData.filter((row) => row.isNew);
            if (newItems.filter((row) => dayjs(row.DueDate).diff(dayjs(row.IssueDate), "day") < 20).length > 0) {
                notification.warning({
                    message: "Warning",
                    description: "Some of the new items have DueDate less than 20 days from IssueDate",
                });
            }
        }

        if (tableData.filter((row) => row.isNew).length > 0) {
            // if there are any items with duplicate InvoiceId, show warning
            const invoiceIds = tableData.map((row) => row.InvoiceId);
            if (invoiceIds.length !== new Set(invoiceIds).size) {
                notification.warning({
                    message: "Warning",
                    description: "Some of the new items have duplicate InvoiceId",
                });
            }
        }

        if (errors.length === 0) {
            omsMutation.mutate(tableData);
        }
    };

    const filteredTableData = useMemo(() => {
        if (showUnpaid) {
            return tableData.filter((item) => !item.IsPaid && (!item.PaidAmount || item.PaidAmount < item.OrderAmount));
        } else {
            return tableData;
        }
    }, [tableData, showUnpaid]);

    const bulkActions: BulkActionType[] = [
        {
            label: "Mark as paid",
            onClick: () =>
                boolModal.show({
                    columnName: "IsPaid",
                    title: "the value",
                    onUpdate: updateData,
                }),
            icon: <DollarOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
    ];

    const tableTitle = () => (
        <Space direction="horizontal" style={{width: "100%", justifyContent: "end"}} split={<Divider type="vertical" />}>
            <UnsavedChangesHandler
                isSaved={lastUpdateTimestamp <= fetchTimestamp}
                disableWhenSaved
                isLoading={omsMutation.isPending}
                onDiscardChanges={onDiscardChanges}
                onSaveChanges={onSaveChanges}
            />
            <CSVDownload
                collection={`AP_Tracker_Export_${dayjs().format("MM-DD-YY")}`}
                data={tableRef.current?.currentData || []}
                isLoading={!tableRef.current}
                dateColumns={["PaidDate", "IssueDate", "DueDate"]}
                parse={(data) => {
                    if (validateAPTracker(data).length > 0) return undefined;

                    return new dataForge.DataFrame(data.map((item) => ({...defaultItem, ...item})))
                        .subset(columns.map((col) => col.dataIndex as string))
                        .toArray();
                }}
            />
            <Space>
                Show unpaid: <Switch checked={showUnpaid} onChange={setShowUnpaid} />
            </Space>
            <Space>
                Editable: <Switch checked={editable} onChange={setEditable} />
            </Space>
            <Button
                onClick={() => {
                    addNewRow();
                }}
                disabled={!editable}
            >
                Add row
            </Button>
            <BulkActionsButton actions={bulkActions} />
        </Space>
    );

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <Collapse>
                <Panel header="Stats" key="stats">
                    <Space split={<Divider type="vertical" />}>
                        <OMSDateStatsSpace<APInvoiceTrackerData>
                            data={tableData}
                            dateColumn="DueDate"
                            amountColumn="OrderAmount"
                            inFuture={true}
                            descriptionRender={(timeFrame) => `Amount with due date in ${timeFrame} days`}
                            titleRender={(timeFrame) => `Due in ${timeFrame} days`}
                        />
                        <OMSDateStatsSpace<APInvoiceTrackerData>
                            data={tableData.filter((item) => !item.IsPaid && (!item.PaidAmount || item.PaidAmount < item.OrderAmount))}
                            dateColumn="DueDate"
                            amountColumn="OrderAmount"
                            inFuture={false}
                            titleRender={() => `Overdue`}
                            merge={true}
                        />
                    </Space>
                </Panel>
            </Collapse>
            <Collapse>
                <Panel header="Periods" key="stats">
                    <Row>
                        {periodData.map((period, index) => (
                            <Col span={6} key={`${index}`}>
                                <Statistic
                                    title={`Period ${index + 1} - ${period.startDate.format("MMM D, YYYY")} to ${period.endDate.format(
                                        "MMM D, YYYY"
                                    )}`}
                                    value={period.currentPeriodData
                                        .filter((row: APInvoiceTrackerData) => !row.IsPaid)
                                        .reduce((acc: number, curr: any) => (acc += curr.OrderAmount), 0)}
                                    precision={2}
                                    prefix="$"
                                />
                            </Col>
                        ))}
                    </Row>
                </Panel>
            </Collapse>
            <EditableTable<APInvoiceTrackerDataRenderType>
                ref={tableRef}
                title={tableTitle}
                tableData={filteredTableData}
                columns={columns}
                loading={isRefetching || apTrackerDataLoading}
                onRowSelectionChange={(rowKeys, availableRowKeys) => setSelectedRowKeys(rowKeys.length === 0 ? availableRowKeys : rowKeys)}
                onRow={(row) => {
                    let style = {};
                    if (!row.IsProcessed) {
                        style = {
                            backgroundColor: "#518A3D55",
                        };
                    }
                    return {
                        style,
                    };
                }}
            />
            <AddLedgerModal
                open={ledgerModalOpen}
                onFinish={(updatedItem) => {
                    setLedgerModalOpen(false);
                    setClickedItem(undefined);
                    // AP Tracker item was updated and is now paid. The change should
                    // already be saved in db, so here just update the local state
                    // silently
                    if (updatedItem) {
                        const newData = [...tableData];
                        const index = newData.findIndex((item) => updatedItem.key === item.key);
                        const item = newData[index];
                        newData.splice(index, 1, {
                            ...item,
                            ...updatedItem,
                        });
                        setTableData(newData);
                    }
                }}
                initialValues={ledgerModalData}
                apTrackerItem={clickedItem}
            />
            {boolModalContext}
        </Space>
    );
};
