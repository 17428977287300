import React, {useState} from "react";
import {Modal, Upload, message, Button} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import type {UploadFile} from "antd/es/upload/interface";

const {Dragger} = Upload;

interface UploadPhotosDialogProps {
    open: boolean;
    onClose: () => void;
    onFinish: (fileList: Array<{ file: UploadFile; bytes: ArrayBuffer }>) => void;
}

const UploadPhotosDialog: React.FC<UploadPhotosDialogProps> = ({open, onClose, onFinish}) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const handleUpload = ({fileList: newFileList}: {fileList: UploadFile[]}) => {
        setFileList(newFileList);
    };

    const getFileBytes = (file: File): Promise<ArrayBuffer> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target?.result as ArrayBuffer);
            reader.onerror = (e) => reject(e);
            reader.readAsArrayBuffer(file);
        });
    };

    const handleFinish = async () => {
        if (fileList.length === 0) {
            message.warning("No photos uploaded");
        }

        try {
            const filesWithBytes = await Promise.all(
                fileList.map(async (file) => ({
                    file,
                    bytes: await getFileBytes(file.originFileObj as File),
                }))
            );
            onFinish(filesWithBytes);
            setFileList([]);
            onClose();
        } catch (error) {
            console.error('Error reading file:', error);
            message.error('An error occurred while processing the files');
        }
    };

    const draggerProps = {
        name: "file",
        multiple: true,
        fileList,
        onChange: handleUpload,
        beforeUpload: () => false, // Prevent auto-upload
    };

    return (
        <Modal
            title="Upload Photos"
            open={open}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="finish" type="primary" onClick={handleFinish}>
                    Finish
                </Button>,
            ]}
        >
            <Dragger listType="picture" {...draggerProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other sensitive files.
                </p>
            </Dragger>
        </Modal>
    );
};

export default UploadPhotosDialog;
