import {ExclamationCircleOutlined} from "@ant-design/icons";
import {WholesaleDataType} from "./WholesaleColumns";
import {ModalStaticFunctions} from "antd/es/modal/confirm";
import React from "react";
import {message, notification} from "antd";
import {FilterValue} from "antd/es/table/interface";
import {ColumnType} from "antd/lib/table";

export const WHOLESALE_DEFAULT_HIDDEN = [
    "UPC",
    // 'Parent',
    "Size",
    "QTY",
    "Cost",
    "BB OOS",
    "AZ OOS",
    "Rating",
    "ReturnConf",
    "ReturnRate",
    "UnsellableRate",
    "AZStock",
    "BBStock",
    "AZTTS",
    "Comments",
    // 'Reviews',
    // 'VarRevs',
    "VarCheck",
    "StockCheck",
    "EstSalesTotal",
    "OMS",
    // 'Amazon Image',
    // 'Orders7D',
    // 'OrdersAll',
    // 'OrdersOur7D',
    // 'OrdersOur14D',
    // 'OrdersOur30D',
    // 'OrdersOur60D',
    // 'OrdersOur90D',
    // 'OrdersOur180D',
    "OrdersOur365D",
    "BBShare",
    "RepProfit",
    "RepToBuy",
    "RepROI",
    "RepEstSales",
    "AvgSellPrice",
    "wsTTS",
    "wsVelTTS",
    "wsGMROI",
    "Tags",
    "DIS",
    "Velocity",
    "SS_Min",
    "SS_Max",
    "Inc30D",
    // 'OrderAmt',
    "PercOrderAmt",
    "ParentOrderAmt",
    "ParentPercOrderAmt",
    "Discrepancy",
    "ShipLater",
    "InvAvailableQuantity",
    "IncAll",
    "TrackedFor",
    "AdiStockAvail",
    "CalcRank",
    "HighRank",
    "LowRank",
    "TotalInv",
    "MinPrice",
    "MaxPrice",
    "Seasonal Tags",
    "StorageFee",
    "ProfitStFee",
    "IsBundle",
    "PackSize",
    "Model",
    "PartNumber",
    "SRDrops",
    // 'Offers',
    "BBSeller",
    "RestrCheck",
    "HazmCheck",
    "ParentStage01Spend",
    "ParentStage01Perc",
    "MVC",
];

export const median = (numbers: number[]) => {
    if (numbers.length > 0) {
        const sorted = numbers.slice().sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);

        if (sorted.length % 2 === 0) {
            return (sorted[middle - 1] + sorted[middle]) / 2;
        }

        return sorted[middle];
    } else {
        return undefined;
    }
};

export const transformBBOwnerFromKeepa = (bbSeller: string, isFBA?: string) => {
    if (bbSeller === "Amazon") {
        return "Amazon";
    } else {
        // clean from "3rd Party (A2NEM58BFPMEIL)" to "A2NEM58BFPMEIL"
        const cleaned = bbSeller.replace(/(3rd Party)|\(|\)/g, "");
        if (cleaned === "?" || cleaned === "") {
            return "None";
        } else {
            if (isFBA) {
                if (isFBA === "yes") {
                    return "FBA";
                } else {
                    return "FBM";
                }
            } else {
                return "Unknown";
            }
        }
    }
};

export const confirmModal = (modal: Omit<ModalStaticFunctions, "warn">, onOk: (...args: any[]) => any, text: any) => {
    modal.confirm({
        title: `Sanity check`,
        icon: <ExclamationCircleOutlined />,
        content: text,
        onOk,
        onCancel() {
            console.log("Cancelled");
        },
    });
};

export const checkProductsForIssues = (products: WholesaleDataType[]): true | string => {
    if (products.find((row) => row.Fees <= 0)) {
        return `Please confirm that you'd like to save an item with lacking fee data `;
    } else if (products.find((row) => row.reviewMultiplier > 1 || (row.variationReviewsTotal >= 0 && row.variationReviewsTotal < 50))) {
        return `Please confirm that you'd like to save an item with lacking review data`;
    } else if (
        products.find((row) => row.ShipLater > 0) &&
        !products
            .find((row) => row.ShipLater > 0)
            ?.sourceCollection.toLowerCase()
            .includes("shiplater")
    ) {
        return `Please confirm that you'd like to save an item with non-zero Ship Later value`;
    } else if (products.find((row) => row.SizeTier === "Special oversize")) {
        return `Please confirm that you'd like to save an item with Special oversize size tier - VERY big item!`;
    } else if (products.find((row) => row.shipDimWeight > 0 && row.weight > 0 && row.shipDimWeight / row.adjustedWeight > 5)) {
        return `Please confirm that you'd like to save an item with big deviation of Pack Weight and Dim Weight (Odd Shape)!`;
    } else if (products.find((row) => row.Issues.includes("Duplicate UPC"))) {
        return `Please confirm that you'd like to save an item that had its UPC matched to multiple ASINs!`;
    } else if (products.find((row) => row.opportunities.includes("Not Available"))) {
        return `Please confirm that you'd like to save an item that is Not Available!`;
    } else {
        return true;
    }
};

export const checkProductsForBigIssues = (products: WholesaleDataType[]): true | string => {
    if (products.find((row) => row.Brand && row.Brand.toLowerCase() === "generic")) {
        return `Unable to save an item with a Brand of "Generic" - please contact Max!`;
    } else {
        return true;
    }
};

export const confirmSave = (
    modal: Omit<ModalStaticFunctions, "warn">,
    products: WholesaleDataType | WholesaleDataType[],
    saveFunc: (...args: any[]) => any
) => {
    const productsToCheck = Array.isArray(products) ? products : [products];

    const bigIssues = checkProductsForBigIssues(productsToCheck);
    if (bigIssues !== true) {
        notification.error({
            message: "Error",
            description: bigIssues,
            duration: 0,
        });
    } else {
        const checkResult = checkProductsForIssues(productsToCheck);
        if (checkResult === true) {
            saveFunc(products);
        } else {
            confirmModal(modal, () => saveFunc(products), checkResult);
        }
    }
};

export const getIssueColor = (issue: string) => {
    switch (true) {
        case issue === "RefFee Mismatch":
            return "warning";
        case issue === "Check ParentRevs":
            return "warning";
        case issue === "Duplicate UPC":
            return "warning";
        case issue === "Coupon Exists":
            return "warning";
        case issue === "Hazmat":
            return "red";
        case issue === "Restricted":
            return "red";
        case issue === "Special oversize":
            return "#f50";
        default:
            return "";
    }
};

export const appendFeeDataToProduct = (product: WholesaleDataType, feeResult: any) => {
    const newItem = {...product};
    const feeList = feeResult.FeesEstimate.FeeDetailList;
    const refFeeResult = feeList.find((fee: any) => fee.FeeType === "ReferralFee");
    const fbaFeeResult = feeList.find((fee: any) => fee.FeeType === "FBAFees");
    newItem.refFeeSPAPI = refFeeResult.FinalFee.Amount;
    newItem.refFeeSPAPIBase = feeResult.FeesEstimateIdentifier.PriceToEstimateFees.ListingPrice.Amount;

    if (fbaFeeResult.FinalFee.Amount > 0) {
        newItem.Fees = fbaFeeResult.FinalFee.Amount;

        // If we don't have S&L Fees applied, we can update the current prepFee variable
        if (!newItem.SmallLightFeeApplied) {
            newItem.prepFee = newItem.Fees;
        }
    }

    return newItem;
};

export const extraFilterClickHandlers = (
    filteredInfo: Record<string, FilterValue | null>,
    setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
    column: ColumnType<any>,
    record: any
) => {
    return (event: React.MouseEvent<any, MouseEvent>) => {
        if (record[column.dataIndex as string]) {
            if (event.ctrlKey) {
                const filterArray = JSON.parse((filteredInfo[column.dataIndex! as string] ?? "[]") as string);

                let newFilterArray: string[] = [];
                // check if the record is already in the filter in the AND section
                if (filterArray.includes(`[AND]${record[column.dataIndex as string]}`)) {
                    // add it to the NOT section
                    newFilterArray = filterArray.filter((item: string) => item !== `[AND]${record[column.dataIndex as string]}`);
                    newFilterArray.push(`[NOT]${record[column.dataIndex as string]}`);
                    message.success(`${column.dataIndex} ${record[column.dataIndex as string]} moved to the NOT filter!`);
                } else {
                    newFilterArray = [...filterArray, `[AND]${record[column.dataIndex as string]}`];
                    message.success(`${column.dataIndex} ${record[column.dataIndex as string]} added to AND filter!`);
                }

                setFilteredInfo({
                    ...filteredInfo,
                    [column.dataIndex as string]: [JSON.stringify(newFilterArray)],
                });
            } else {
                if ((event.target as HTMLElement).nodeName.includes("TD")) {
                    navigator.clipboard
                        .writeText(record[column.dataIndex as string])
                        .then((x) => {
                            message.success(`${column.dataIndex} copied!`);
                        })
                        .catch((err) => {
                            console.log("failed copy", err);
                            message.error(`Failed to copy ${column.dataIndex}!`);
                        });
                }
            }
        }
    };
};
