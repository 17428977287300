import React, {useContext, useState, useEffect, useRef} from "react";
import {useSuppliers} from "../components/omega/oms/dataHandlers";
import {Supplier} from "../types/OmegaTypes";
import {useAuth} from "./AuthContext";
import {
    getGlobalNotes,
    getSupplierItems,
    getSupplierJournals,
    getSupplierNotes,
    postSupplierItems,
    postSupplierJournals,
    postSupplierNotes,
    postSupplierUpdate,
} from "../services/BrandService";
import {Form, Input, Modal, message} from "antd";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {
    BrandDashboardItem,
    BrandDashboardItemRenderType,
    BrandDashboardJournalEntry,
    GlobalNoteItem,
    SupplierNoteItem,
} from "../types/Brand";
import {JoinSlackChannel, SendToSlackBlocks} from "../services/OmegaService";
import {SLACK_CHANNEL_IDS, SLACK_USER_IDS} from "../components/utilities/common";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {ModalStaticFunctions} from "antd/es/modal/confirm";

const SupplierContext = React.createContext<{
    suppliers: Supplier[];
    selectedSupplier: Supplier | undefined;
    setSelectedSupplier: React.Dispatch<React.SetStateAction<Supplier | undefined>>;
    selectedSupplierItems: BrandDashboardItem[];
    selectedSupplierJournals: BrandDashboardJournalEntry[];
    selectedSupplierNotes: SupplierNoteItem[];
    selectedSupplierItemNotes: GlobalNoteItem[];
    updateSupplier: (updateData: any, source: string, updateElement: string, sourceText: string) => void;
    updateSupplierItems: (updateData: any) => void;
    updateSupplierJournals: (updateData: any) => void;
    updateSupplierNotes: (updateData: any) => void;
}>({
    suppliers: [],
    selectedSupplier: undefined,
    setSelectedSupplier: () => {},
    selectedSupplierItems: [],
    selectedSupplierJournals: [],
    selectedSupplierNotes: [],
    selectedSupplierItemNotes: [],
    updateSupplier: () => {},
    updateSupplierItems: () => {},
    updateSupplierJournals: () => {},
    updateSupplierNotes: () => {},
});

export function useSupplierContext() {
    return useContext(SupplierContext);
}

const GetUrlToSource = (source: string) => {
    switch (source) {
        case "values":
            return "&brand_info_tab=values&dashboard_tab=information";
        case "purchasing_guide":
            return "&brand_info_tab=purchasing_guide&dashboard_tab=information";
        case "notes":
            return "&brand_info_tab=notes&dashboard_tab=information";
        case "buyers_notes":
            return "&brand_info_tab=buyers_notes&dashboard_tab=information";
        case "journal":
            return "&brand_info_tab=journal&dashboard_tab=information";
        case "items":
            return "&dashboard_tab=items";
        default:
            return "";
    }
};

function SendUpdateToSlack(
    modal: Omit<ModalStaticFunctions, "warn">,
    supplier: Supplier,
    user: string,
    source: string,
    updateElement: string,
    text: string,
    formRef: any,
    url?: URL
) {
    modal.confirm({
        title: `Do you want to send a Slack update for your changes?`,
        icon: <ExclamationCircleOutlined />,
        content: (
            <Form
                form={formRef}
                name={`ping-title`}
                layout="vertical"
                preserve={true}
                initialValues={{
                    title: ``,
                }}
            >
                <Form.Item name={"title"} key={"title"} label={`Do you want to add a custom update header suffix?`}>
                    <Input></Input>
                </Form.Item>
            </Form>
        ),
        onOk: async () => {
            const pingTitle = formRef.getFieldValue("title");
            // @ts-ignore
            const pingsToSend: string[] =
                process.env.NODE_ENV === "development"
                    ? []
                    : [
                          ...(supplier.dataValues?.brandStrategist ?? []),
                          ...(supplier.dataValues?.brandStakeholders ?? []),
                          supplier.dataValues?.brandManager,
                      ].filter((el) => el !== undefined && el !== "" && el.length > 0);

            const whoMadeEdit = SLACK_USER_IDS[user as keyof typeof SLACK_USER_IDS]
                ? `<@${SLACK_USER_IDS[user as keyof typeof SLACK_USER_IDS]}>`
                : user;

            const blocks = [
                {
                    type: "header",
                    text: {
                        type: "plain_text",
                        text: `*${supplier.name}* Dashboard Update${pingTitle ? ` - ${pingTitle}` : ""}`,
                    },
                },
                {
                    type: "context",
                    elements: [
                        {
                            type: "mrkdwn",
                            text: `*Who:* ${whoMadeEdit}`,
                        },
                        {
                            type: "mrkdwn",
                            text: `*CC:* ${pingsToSend
                                .map((email: string) => `<@${SLACK_USER_IDS[email as keyof typeof SLACK_USER_IDS]}>`)
                                .join(", ")}`,
                        },
                    ],
                },
                {
                    type: "section",
                    text: {
                        type: "mrkdwn",
                        text: text,
                    },
                    accessory: {
                        type: "button",
                        text: {
                            type: "plain_text",
                            text: "Link",
                        },
                        url: url ?? `https://sourcing.projectsuite.io/brand?selected_supplier=${supplier.name}${GetUrlToSource(source)}`,
                    },
                },
            ];

            const channelToSend = supplier.dataValues?.brandChannel
                ? supplier.dataValues.brandChannel.split("/")!.pop()!.split("?")[0]
                : SLACK_CHANNEL_IDS.DASHBOARD_UPDATES;

            return SendToSlackBlocks(channelToSend, blocks).then((res) => {
                if (res.error) {
                    if (res.error === "channel_not_found") {
                        message.error("Failed to send update to Slack! Channel not found!");
                        return;
                    } else if (res.error === "not_in_channel") {
                        JoinSlackChannel(channelToSend, user).then((res) => {
                            if (res.error) {
                                message.error("Failed to send update to Slack! Failed to join channel!");
                            } else {
                                SendToSlackBlocks(channelToSend, blocks).then((res) => {
                                    if (res.error) {
                                        message.error("Failed to send update to Slack!");
                                    } else {
                                        message.success("Update sent to Slack!");
                                    }
                                });
                            }
                        });
                    } else {
                        message.error("Failed to send update to Slack!");
                    }
                } else {
                    message.success("Update sent to Slack!");
                }
            });
        },
        onCancel() {
            console.log("Cancelled download");
        },
    });
}

export function SupplierProvider({children}: {children: any}) {
    const {data: suppliers} = useSuppliers();
    const {currentUser, userData} = useAuth();
    const queryClient = useQueryClient();
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier>();
    const [selectedSupplierItems, setSelectedSupplierItems] = useState<BrandDashboardItem[]>([]);
    const [selectedSupplierItemNotes, setSelectedSupplierItemNotes] = useState<GlobalNoteItem[]>([]);
    const [selectedSupplierNotes, setSelectedSupplierNotes] = useState<SupplierNoteItem[]>([]);
    const [selectedSupplierJournals, setSelectedSupplierJournals] = useState<BrandDashboardJournalEntry[]>([]);
    const lastUpdateSent = useRef<number>(0);
    const [modal, contextHolder] = Modal.useModal();
    const [formRef] = Form.useForm();

    const supplierItemsQuery = useQuery({
        queryKey: [selectedSupplier?.name, "supplier_items"],
        queryFn: () =>
            selectedSupplier ? currentUser!.getIdToken().then((token: string) => getSupplierItems(token, selectedSupplier.name)) : [],
    });

    const {data: noteData} = useQuery({
        queryKey: ["global_note_data", selectedSupplier?.name, selectedSupplierItems],
        queryFn: () =>
            currentUser!.getIdToken().then((token: string) =>
                getGlobalNotes(
                    token,
                    "ASIN",
                    selectedSupplierItems!.map((prod) => prod.ASIN)
                )
            ),
        staleTime: Infinity,
        enabled: !!(selectedSupplierItems && selectedSupplierItems.length > 0),
    });

    const supplierJournalsQuery = useQuery({
        queryKey: [selectedSupplier?.name, "supplier_journals"],
        queryFn: () =>
            selectedSupplier ? currentUser!.getIdToken().then((token: string) => getSupplierJournals(token, selectedSupplier.name)) : [],
    });

    const notesQuery = useQuery({
        queryKey: [selectedSupplier?.name, "supplier_notes"],
        queryFn: () =>
            selectedSupplier ? currentUser!.getIdToken().then((token: string) => getSupplierNotes(token, selectedSupplier!.name)) : [],
    });

    const updateSupplier = (updateData: any, source: string, updateElement: string, sourceText: string) => {
        currentUser
            ?.getIdToken()
            .then((token) => postSupplierUpdate(token, updateData))
            .then((res) => {
                if (res.error) {
                    message.error("Supplier failed to update!");
                } else {
                    message.success("Supplier updated successfully!");
                    const currentSupplier = suppliers?.find((supplier) => supplier.name === updateData.name)!;
                    let newSupplierData: Supplier;
                    if (Object.keys(updateData).some((key) => key.includes("supplierNotes"))) {
                        const updatedKey = Object.keys(updateData).find((key) => key.includes("supplierNotes"))!;

                        newSupplierData = {
                            ...currentSupplier,
                            supplierNotes: {
                                ...currentSupplier.supplierNotes,
                                [updatedKey.split(".")[1]]: updateData[updatedKey],
                            },
                        };
                    } else {
                        newSupplierData = {...currentSupplier, ...updateData};
                    }

                    queryClient.setQueryData(["supplier_names"], (oldSuppliers: Supplier[] | undefined) => {
                        const newSuppliers = [...(oldSuppliers ?? [])].map((supplier) =>
                            supplier.name === updateData.name ? newSupplierData : supplier
                        );
                        return newSuppliers;
                    });

                    // only send an update if there hasn't been on sent in the past 5 minutes
                    let overwrite = process.env.NODE_ENV === "development";
                    if (overwrite || Date.now() - lastUpdateSent.current >= 10 * 60 * 1000) {
                        lastUpdateSent.current = Date.now();
                        SendUpdateToSlack(modal, currentSupplier, userData!.email, source, updateElement, sourceText, formRef);
                    }

                    if (selectedSupplier?.name === updateData.name) {
                        setSelectedSupplier(currentSupplier);
                    }
                }
            });
    };

    const updateSupplierItems = (updateData: BrandDashboardItemRenderType[]) => {
        currentUser
            ?.getIdToken()
            .then((token) => postSupplierItems(token, updateData))
            .then((res) => {
                if (res.error) {
                    message.error("Supplier items failed to update!");
                } else {
                    message.success("Supplier items updated successfully!");

                    SendUpdateToSlack(modal, selectedSupplier!, userData!.email, "items", "Items", "Items have been updated!", formRef);
                    queryClient.invalidateQueries({queryKey: [selectedSupplier?.name, "supplier_items"]});
                }
            });
    };

    const updateSupplierJournals = (updateData: BrandDashboardJournalEntry[]) => {
        currentUser
            ?.getIdToken()
            .then((token) => postSupplierJournals(token, updateData))
            .then((res) => {
                if (res.error) {
                    message.error("Supplier journals failed to update!");
                } else {
                    message.success("Supplier journals updated successfully!");
                    queryClient.setQueryData(
                        [selectedSupplier?.name, "supplier_journals"],
                        (oldSupplierItems: BrandDashboardJournalEntry[] | undefined) => {
                            const newSupplierJournals = [...updateData, ...(oldSupplierItems ?? [])];
                            return newSupplierJournals;
                        }
                    );

                    SendUpdateToSlack(
                        modal,
                        selectedSupplier!,
                        userData!.email,
                        "journal",
                        "Journals",
                        "Journals have been updated!",
                        formRef
                    );
                    setSelectedSupplierJournals((oldSupplierJournals) => [...updateData, ...oldSupplierJournals]);
                }
            });
    };

    const updateSupplierNotes = (updateData: any) => {
        currentUser
            ?.getIdToken()
            .then((token) =>
                postSupplierNotes(
                    token,
                    updateData.map((note: SupplierNoteItem) => ({...note, author: userData!.email}))
                )
            )
            .then((res) => {
                if (res.error) {
                    message.error("Supplier Notes failed to update!");
                } else {
                    message.success("Supplier Notes updated successfully!");
                    const url = new URL(window.location.href);
                    url.searchParams.set("note_title", updateData[0].title);
                    SendUpdateToSlack(
                        modal,
                        selectedSupplier!,
                        userData!.email,
                        "notes",
                        "Notes",
                        "Notes have been updated: " + updateData[0].title,
                        formRef,
                        url
                    );
                    queryClient.invalidateQueries({queryKey: [selectedSupplier?.name, "supplier_notes"]});
                }
            });
    };

    useEffect(() => {
        if (supplierItemsQuery.data) {
            setSelectedSupplierItems(supplierItemsQuery.data);
        }
    }, [supplierItemsQuery.data]);

    useEffect(() => {
        if (supplierJournalsQuery.data) {
            setSelectedSupplierJournals(supplierJournalsQuery.data);
        }
    }, [supplierJournalsQuery.data]);

    useEffect(() => {
        if (noteData) {
            setSelectedSupplierItemNotes(noteData);
        }
    }, [noteData]);

    useEffect(() => {
        if (notesQuery.data) {
            setSelectedSupplierNotes(notesQuery.data);
        }
    }, [notesQuery.data]);

    const contextValue = {
        suppliers: suppliers ?? [],
        selectedSupplier,
        setSelectedSupplier,
        updateSupplier,
        selectedSupplierItems,
        selectedSupplierJournals,
        selectedSupplierItemNotes,
        selectedSupplierNotes,
        updateSupplierItems,
        updateSupplierJournals,
        updateSupplierNotes,
    };

    return (
        <SupplierContext.Provider value={contextValue}>
            {contextHolder}
            {children}
        </SupplierContext.Provider>
    );
}
