import {notification} from "antd";
import {AppState, ImmerStateCreator, ReturnsSlice} from "../../types/storeTypes";
import {makeCall} from "../../services/common";
import {ReturnProduct} from "../../types/WarehouseTypes";
import {fetchProductsData} from "../../components/warehouse/receiving/helpers";

const initialReturnsData = {
    code: undefined,
    expectedProducts: [],
    extractedItems: [],
    selectedItem: undefined,
    isLoading: false,
    isScanning: false,
};

/**
 * Makes sure that the tracking number is not too long. Typically tracking numbers
 * have 18 characters, but USPS barcodes encode over 30 digits in which only last 22
 * are the tracking number.
 * @param {string} trackingNumber
 */
function parseTrackingNumber(trackingNumber: string) {
    return trackingNumber.slice(-22);
}

export const createReturnsSlice: ImmerStateCreator<AppState, ReturnsSlice> = (set, get) => ({
    returnsData: initialReturnsData,
    setCode: (code: string | undefined) => {
        set((state) => {
            state.returnsData.code = code;
        });
    },
    setIsScanning: (isScanning: boolean) => {
        set((state) => {
            state.returnsData.isScanning = isScanning;
        });
    },
    setSelectedItem: (item: ReturnProduct | undefined) => {
        set((state) => {
            state.returnsData.selectedItem = item;
        });
    },
    getExpectedProducts: async (trackingNumber: string) => {
        trackingNumber = parseTrackingNumber(trackingNumber);
        if (!trackingNumber) {
            notification.error({
                message: "Error",
                description: "Please enter a tracking number",
            });
            return;
        }

        set((state) => {
            state.returnsData.isLoading = true;
        });

        try {
            // First, call the 'getProducts' endpoint
            const productsResponse = await makeCall("returns_v2/getProducts", {trackingNumber}, "GET");
            const products = productsResponse.result as ReturnProduct[];

            // Then, call the 'getExpectedProducts' endpoint
            const expectedResponse = await makeCall("returns_v2/getExpectedProducts", {trackingNumber}, "GET");
            const expectedProducts = expectedResponse.result as ReturnProduct[];

            // Merge the products with expected products by SKU
            const mergedProducts = expectedProducts.map((expected) => {
                const match = products.find((product) => product.sku === expected.sku);
                return match ? {...expected, ...match} : expected;
            });

            if (mergedProducts.length === 0) {
                throw new Error("No products found for this tracking number");
            }

            set((state) => {
                state.returnsData.expectedProducts = mergedProducts;
                state.returnsData.isLoading = false;
                state.returnsData.code = undefined;
            });
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
            set((state) => {
                state.returnsData.isLoading = false;
            });
        }
    },
    scanReturnedProduct: async (code: string) => {
        set((state) => {
            state.returnsData.isScanning = true;
            state.returnsData.code = code;
        });

        try {
            // Call the searchCustomerReturns endpoint
            const response = await makeCall("returns_v2/searchCustomerReturns", {lpn: code}, "GET");
            const lpnData = response.result[0];

            if (!lpnData) {
                throw new Error("Product not found in reports");
            }

            set((state) => {
                // Find and update the matching expected product
                const index = state.returnsData.expectedProducts.findIndex((p) => p.sku === lpnData.sku);
                if (index !== -1) {
                    state.returnsData.expectedProducts[index] = {
                        ...state.returnsData.expectedProducts[index],
                        ...lpnData,
                        scanned: true,
                    };
                }
            });

            // Fetch additional product data using the ASIN
            if (lpnData.asin) {
                const productData = await fetchProductsData(lpnData.asin, "ASIN");
                set((state) => {
                    const matchingProduct = state.returnsData.expectedProducts.find((p) => p.asin === lpnData.asin && !p.finished);
                    if (matchingProduct) {
                        state.returnsData.extractedItems = productData.map((item) => ({
                            ...item,
                            ...matchingProduct,
                        }));
                    } else {
                        state.returnsData.extractedItems = [];
                    }
                });
            }
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
            set((state) => {
                state.returnsData.code = undefined;
            });
        } finally {
            set((state) => {
                state.returnsData.isScanning = false;
            });
        }
    },
    resetReturnsData: () => {
        set((state) => {
            state.returnsData = initialReturnsData;
        });
    },
    setExtractedItems: (items: ReturnProduct[]) => {
        set((state) => {
            state.returnsData.extractedItems = items;
        });
    },
    saveReturnedProduct: async (product: ReturnProduct) => {
        try {
            const productToSave: ReturnProduct = {...product, finished: true, scanDate: new Date().toISOString()};

            let index = get().returnsData.expectedProducts.findIndex(
                (p) => p.sku === productToSave.sku && p["license-plate-number"] === productToSave["license-plate-number"]
            );
            if (index === -1) {
                index = get().returnsData.expectedProducts.findIndex((p) => p.sku === productToSave.sku);
            }

            if (index === -1) {
                throw new Error("Not found in expected products");
            }

            set((state) => {
                state.returnsData.expectedProducts[index] = productToSave;
            });

            await makeCall("returns_v2/addProducts", {}, "POST", {products: [productToSave]});
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
        }
    },
    processWithoutLPN: async (sku: string) => {
        try {
            set((state) => {
                state.returnsData.isScanning = true;
            });
            const index = get().returnsData.expectedProducts.findIndex((p) => p.sku === sku && !p.finished);
            if (index === -1) {
                throw new Error("Not found in expected products");
            }

            const product = get().returnsData.expectedProducts[index];
            const lpn = `NO-LPN-${product["order-id"]}-${product.fnsku}-${index}`;

            set((state) => {
                state.returnsData.expectedProducts[index] = {
                    ...product,
                    "license-plate-number": lpn,
                    "scanDate": new Date().toISOString(),
                };
            });

            if (product.asin) {
                const productData = await fetchProductsData(product.asin, "ASIN");
                set((state) => {
                    const matchingProduct = state.returnsData.expectedProducts.find((p) => p.asin === product.asin && !p.finished);
                    if (matchingProduct) {
                        state.returnsData.extractedItems = productData.map((item) => ({
                            ...item,
                            ...matchingProduct,
                        }));
                    } else {
                        state.returnsData.extractedItems = [];
                    }
                    state.returnsData.isScanning = false;
                });
            }
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
        }
    },
});
